// Redmine
export const SET_REDMINE_ADDRESS = 'SET_REDMINE_ADDRESS';
export const SET_REDMINE_KEY = 'SET_REDMINE_KEY';

// Projects
export const GET_PROJECTS_START = 'GET_PROJECTS_START';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const SET_PROJECT_DEFAULT_ISSUE = 'SET_PROJECT_DEFAULT_ISSUE';
export const SET_ISSUES_FOR_PROJECT = 'SET_ISSUES_FOR_PROJECT';

// Project Aliases
export const SET_PROJECT_ALIAS = 'SET_PROJECT_ALIAS';

// Time Entries
export const CREATE_TIME_ENTRY = 'CREATE_TIME_ENTRY';
export const UPDATE_TIME_ENTRY = 'UPDATE_TIME_ENTRY';
export const DELETE_TIME_ENTRY = 'DELETE_TIME_ENTRY';

// Pending Time Entries
export const CREATE_PENDING_ENTRY_START = 'CREATE_PENDING_ENTRY';
export const CREATE_PENDING_ENTRY_SUCCESS = 'CREATE_PENDING_ENTRY_SUCCESS';
export const CREATE_PENDING_ENTRY_FAILURE = 'CREATE_PENDING_ENTRY_FAILURE';

// Tutorials
export const SET_TUTORIAL_VIEWED = 'SET_TUTORIAL_VIEWED';
